*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-family: 'Barlow Condensed', sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@-ms-viewport {
  width: device-width;
}
article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
body {
  margin: 0;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  background-color: #2445a6;
  color: #d1eefe;
}
[tabindex="-1"]:focus {
  outline: 0 !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: 'Barlow Condensed', sans-serif;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 500;
}
dd {
  margin-bottom: .5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -.25em;
}
sup {
  top: -.5em;
}
a {
  color: #eec666;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #eec666;
  text-decoration: underline;
}
a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}
pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.2;
  color: inherit;
}
h1,
.h1 {
  font-size: 3.3125rem;
}
h2,
.h2 {
  font-size: 2.25rem;
}
h3,
.h3 {
  font-size: 1.5625rem;
}
h4,
.h4 {
  font-size: 1.125rem;
}
h5,
.h5 {
  font-size: 1.0625rem;
}
h6,
.h6 {
  font-size: 0.75rem;
}
*:focus {
  outline: 0;
}
a {
  color: #eec666;
}

/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
*::-webkit-scrollbar-thumb {
  background-color: #f54b31;
}
/* Mobile Navigation */
.mobile_nav {
  background-color: #2445a6 !important;
  height: 100% !important;
}
.hamburger_btn {
  color: #d1eefe !important;
}
.home_page_menu li::after {
  display: none;
}
.modal-backdrop.show {
  opacity: .75;
}
/* Custom Styles */

/* Login & Register Pages */
.logo_div img {
  width: 200px;
}
form.auth_form {
  padding: 50px 100px 0;
}
.auth_link {
  font-size: 14px;
  color: #eec666;
  display: block;
  margin-top: 12px;
}
.auth_link:hover {
  color: #eec666;
  text-decoration: underline;
}
.auth_social_links {
  text-align: center;
}
.auth_social_links p {
  color: #c6c6c6;
  font-size: 14px;
  margin: 10px 0 0px;
}
.auth_social_links ul {
  list-style: none;
  padding: 0;
}
.auth_social_links ul li {
  display: inline-block;
  margin: 8px;
}
.auth_social_links ul li a {
  background-color: #eec666;
  color: #003fca;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
}
form.auth_form .custom-control-label {
  margin-bottom: 0;
  position: initial;
  vertical-align: initial;
}
.auth_form p {
  font-size: 14px;
}
.auth_form h2 {
  font-size: 22px;
  color: #f2d489;
}
.auth_panel.forgot_panel {
  padding-bottom: 50px;
}
button.menu_btn {
  font-size: 24px;
  font-weight: 500;
  padding: 2px 30px;
  border: 2px solid #f54b31;
  background-color: #f54b31;
  color: #fff;
  border-radius: 50px;
  text-transform: capitalize;
}
button.menu_btn:hover {
  border: 2px solid #f54b31;
  background-color: transparent;
  color: #f54b31;
}
.top_navbar ul {
  display: flex;
  align-items: center;
}
.top_nav_login {
  color: #d1eefe !important;
  font-size: 24px;
  text-transform: capitalize;
  cursor: pointer;
  font-weight: 400;
}
.top_nav_login:hover,
.top_nav_login.active {
  color: #fff !important;
}
.banner_btn_grps button:nth-child(2) {
  margin-left: 15px;
}
/* Dashboard Pages */
.inner_pageheader {
  padding-top: 15vh !important;
  padding-bottom: 100px !important;
}
.dash_top_bar {
  position: relative;
  overflow: hidden;
}
/* .dash_top_bar::before {
  content: "";
  background-image: url("../src/assets/images/dash_bg.png");
  background-position: center;
  width: 100%;
  height: 620px;
  position: absolute;
  background-size: cover;
} */
.content_page h2 {
  color: #991d25;
  font-size: 28px;
}
.content_page h3 {
  color: #991d25;
  font-size: 18px;
}
.content_page p,
.content_page ul li{
  color: #991d25;
  font-size: 14px;
}
.content_page h4 {
  color: #991d25;
  font-size: 14px;
  font-weight: 600;
}
.dash_top_bar.content_page::before {
  height: 100%;
  background: #fff;
}
.content_page a {
  color: #991d25;
}
/* .dash_top_bar::after {
  content: "";
  background-image: url("../src/assets/images/dash_bg_left.png");
  background-position: left bottom;
  width: 545px;
  height: 586px;
  position: absolute;
  left: -120px;
  top: 0;
} */
.dash_menu {
  z-index: 5;
}
.dash_menu li a button.menu_btn {
  padding: 10px 20px;
  width: 230px;
  font-size: 18px;
}
.dash_menu li a.active button.menu_btn {
  background-color: #991d25;
}
.dash_box_panel {
  background-image: linear-gradient(2deg,#0040cf,#991d25);
  border-radius: 30px;
  box-shadow: -1px -1px 3px -1px rgb(195 195 195/40%), 1px 2px 3px -1px rgb(0 0 0/.8);
  padding: 5px 20px 20px;
  margin-top: 20px;
  text-align: center;
  min-height: 265px;
  z-index: 10;
  position: relative;
}
img.user_img {
  width: 60px;
  border-radius: 50%;
}
.dash_box_panel h2 {
  font-size: 16px;
  color: #cccccc;
  font-weight: 500;
}
.user_name {
  color: #f3d690;
  font-size: 16px;
  font-weight: 500;
}
.kyc_status {
  color: #0dc81e;
  font-size: 12px;
}
.edit_icon a i {
  font-size: 20px;
  color: #f3d690;
}
a.tb_btn span {
  font-size: 12px;
  margin-left: 5px;
}
a.tb_btn {
  background: #991d25;
  padding: 1px 8px;
  border-radius: 30px;
  color: #fff;
  text-decoration: none;
}
.tb_btn img {
  width: 18px;
  height: 18px;
  vertical-align: middle;
}
h1.token_bal {
  font-size: 40px;
  color: #f3d690;
}
h1.token_bal span {
  font-size: 18px;
  color: #cccccc;
  text-transform: uppercase;
  display: block;
}
.token_bal_note {
  font-size: 13px;
}
.wb_btn span {
  font-size: 20px;
  color: #f3d690;
  margin-left: 5px;
}
.wb_btn {
  margin: 25px 0;
}
.wallet_address_panel {
  background-image: linear-gradient(180deg,#1a3a96 0%,#2445a6 100%);
  border-radius: 10px;
  box-shadow: -1px -1px 8px 2px rgb(0 0 0 / 20%), 1px 2px 7px 3px rgb(0 0 0 / 0.1);
  padding: 20px 50px 10px;
  /* margin-top: 30px; */
  color: #000;
}
.table_box {
  background-image: linear-gradient(180deg,#1a3a96 0%,#2445a6 100%);
  border-radius: 10px;
  box-shadow: -1px -1px 8px 2px rgb(0 0 0 / 20%), 1px 2px 7px 3px rgb(0 0 0 / 10%);
  padding: 30px;
  margin-top: 50px;
}
.wallet_address_panel h2,
.table_box h2 {
  color: #d1eefe;
  font-size: 32px;
  font-weight: 600;
}
.rdt_TableRow,
.rdt_TableHeadRow,
.rdt_Table{
  background: transparent !important;
}
.rdt_TableCol_Sortable{
  color: #ffffff !important;
  font-family: 'Titillium Web', sans-serif !important;
  font-size: 16px !important;
  font-weight: 600;
}
.rdt_TableCell{
  color: #ffffff;
  font-size: 16px;
  /* border-top: 1px solid #202282; */
}
.rdt_Table > div {
  background: #1c398e !important;
  color: #fff !important;
}
.rdt_TableRow,
.rdt_TableHeadRow {
  border-bottom-color: rgb(255 255 255 / 12%) !important;
}
.rdt_Pagination {
  border-top-color: rgb(255 255 255 / 12%) !important;
}
button.tron_link_btn {
  background-color: #991d25;
  color: #fff;
  font-size: 22px;
  text-transform: capitalize;
  padding: 7px 45px;
  border-radius: 30px;
  vertical-align: middle;
}
button.tron_link_btn img {
  margin-right: 10px;
  vertical-align: middle;
}
button.tron_link_btn:hover {
  background-color: #b0323b;
}
.wallet_address span {
  font-size: 20px;
  color: #991d25;
  word-break: break-all;
}
.wallet_address  {
  color: #626262;
  font-size: 14px;
  margin: 18px 0;
  text-align: center;
}
.djack_equavalent {
  font-size: 24px;
  text-align: center;
  color: #d1eefe;
  font-weight: 500;
}
.djack_equavalent span {
  color: #ececec;
}
.form_text_prepend {
  position: relative;
}
.form_text_prepend span {
  position: absolute;
  color: #000;
  top: 8px;
  z-index: 1;
  right: 15px;
  font-size: 18px;
  background-color: #fff;
}
.text_red {
  color: #e69f35;
}
footer.footer_inner h2 {
  color: #991d25;
  font-size: 16px;
  margin-bottom: 15px;
}
footer.footer_inner p {
  color: #274782;
  font-size: 11px;
  line-height: 16px;
}
footer.footer_inner ul {
  padding: 0;
  margin: 0;
}
footer.footer_inner ul.quick_links li {
  display: block;
}
footer.footer_inner ul.quick_links li a {
  color: #46d5db;
  font-size: 10px;
  text-transform: uppercase;
  padding: 0;
}
footer.footer_inner ul.quick_links li a:hover {
  color: #bb0000;
}
.copyright_txt {
  color: #fff;
  font-size: 18px;
  text-align: left;
  margin-top: 5px;
}
.copyright_txt a {
  color: #fff;
  font-size: 18px;
}
footer.footer_inner {
  background-color: #f5e8e9;
}
ul.social_links li {
  display: inline-block;
  margin: 8px;
}
ul.social_links li a {
  color: #8193c5;
  font-size: 22px;  
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-radius: 100%;
}
ul.social_links li a:hover {
  color: #fff;
}
.font_14 {
  font-size: 14px;
}
.login_social_links {
  display: block;
  text-align: center;
}
.login_social_links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.login_social_links ul li {
  display: inline-block;
  margin: 0 12px;
}
.login_social_links ul li a img {
  transition: all 0.2ms;
}
.login_social_links ul li a:hover img {
  filter: drop-shadow(2px 2px 8px rgb(0 0 0 / 30%));
}

 /* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
 /* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
 /* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
 /* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
 /* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */


/* Home Css */
button,ol,li,a {
  font-family: 'Barlow Condensed', sans-serif !important;
}
section {
  padding: 60px 0;
}
.homepage_container {
  padding-top: 200px !important;
  padding-bottom: 200px !important;
}
.header_home header {
  z-index: 1020;
  background: transparent;
  border-radius: 0;
  box-shadow: none;
}
.page-header h1 {
  color: #fff;
  font-size: 50px;
  font-weight: 500;
  margin: 10px 0 35px;
  line-height: 64px;
}
.page-header h4 {
  color: #d1eefe;
  font-size: 20px;
  position: relative;
  font-weight: 400;
}
.page-header button.primary_btn {
  font-size: 35px;
  margin-top: 30px;
  padding: 3px 60px;
}
.page-header button.primary_btn a {
  color: #fff;
  text-decoration: none;
}
button.primary_btn {
  font-size: 24px;
  font-weight: 600;
  margin-top: 5px;
  padding: 5px 40px;
  border: 2px solid #f54b31;
  background-color: #f54b31;
  color: #fff;
  border-radius: 50px;
  text-transform: capitalize;
}
button.primary_btn:hover {
  background-color: transparent;
  border: 2px solid #f54b31;
  color: #f54b31;
}
h1.main_title {
  color: #ffffff;
  font-size: 50px;
  font-weight: 600;
  padding-bottom: 30px;
  margin: 0;
  position: relative;
}
p.home_para {
  color: #d1eefe;
  font-size: 20px;
}
h5.count_txt {
  font-size: 22px;
  font-weight: 700;
  color: #5781fa;
}
.about_section h3 {
  font-size: 36px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 15px;
}
.why_choose_section {  
  padding-bottom: 150px;
}
.dark_blue_bg {
  background-color: #1a3a96;
}
.why_choose_panel {
  display: flex;
  flex-wrap: wrap;
}
.why_choose_div {
  width: 40%;
  padding: 10px 30px;
  border-radius: 30px;
  background: transparent;
  margin: 30px;
  transition: 0.2s all;
  height: 250px;
}
.why_choose_div h2 {
  color: #3559bf;
  font-size: 100px;
  font-weight: 700;
}
.why_choose_div h3 {
  color: #c9e5f9;
  font-size: 40px;
  font-weight: 400;
  position: relative;
  padding-bottom: 70px;
}
.why_choose_div h3::after {
  content: "";
  background-color: #092167;
  width: 190px;
  height: 3px;
  position: absolute;
  box-shadow: 0 0 8px 5px rgb(255 255 255 / 20%);
  bottom: 30px;
  left: 0;
}
.why_choose_div h5 {
  color: #fff;
  font-size: 22px;
  font-weight: 400;
  display: none;
  transition: 0.2s all;
}
.why_choose_div:hover {
  position: relative;
  z-index: 1;
  cursor: default;
}
.why_choose_div:hover h5 {
  display: block;
}
.why_choose_div:hover::before {
  content: "";  
  background: #f54b31;
  width: 100%;
  height: 400px;
  position: absolute;
  z-index: -1;
  left: 0;
  border-radius: 30px;
}
.why_choose_div:hover h2 {
  color: #fff;
}
.why_choose_div:hover h3::after {
  background-color: #fda591;
}
.why_choose_div:hover::after {
  content: "";
  background: url("../src/assets/images/why_choose_icon.png");
  width: 162px;
  height: 166px;
  position: absolute;
  top: -30px;
  right: -34px;
}
.ico_details_panel {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.ico_details_panel h2 {
  font-size: 24px;
  font-weight: 400;
  color: #d1eefe;
  margin: 0;
}
.ico_details_panel p {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0;
}
.ico_details_panel > div {
  width: 45%;
  margin-top: 15px;
  background-color: #375ace;
  padding: 10px 20px;
  border-radius: 5px;
  border-left: 5px solid #f54b31;
}
.ico_details_panel_div .ico_details_panel:nth-child(2) div {
  background-color: #2b4bb2;
}
.ico_details_panel h4 {
  font-size: 18px;
  color: #e69f35;
  margin: 0;
}
.chart_asset_details ul {
  list-style: none;
  padding: 0 0 0 50px;
  margin: 0;
}
.chart_asset_details ul li {
  position: relative;
  padding: 10px 0;
}
.chart_asset_details ul li label {
  font-size: 24px;
  color: #fff;
}
.chart_asset_details ul li label:nth-child(1) {
  width: 50%;
}
.chart_asset_details ul li::before {
  content: "";
  background-color: #625f9a;
  width: 7px;
  height: 16px;
  position: absolute;
  border-radius: 10px;  
  left: -25px;
  top: 22px;
}
.chart_asset_details ul li:nth-child(2):before {
  background-color: #f2759d;
}
.chart_asset_details ul li:nth-child(3):before {
  background-color: #15b0dd;
}
.chart_asset_details ul li:nth-child(4):before {
  background-color: #b73cb6;
}
.chart_asset_details ul li:nth-child(5):before {
  background-color: #20a968;
}
.chart_asset_details ul li:nth-child(6):before {
  background-color: #e56b31;
}
.chart_asset_details ul li:nth-child(7):before {
  background-color: #eab028;
}
.chart_asset_details ul li:nth-child(8):before {
  background-color: #4f86d7;
}
.roadmap_section {
  padding: 50px 0 120px;
}
.roadmap_carousel_div {
  background: url("../src/assets/images/roadmap_01.png");
  width: 320px;
  height: 452px;
  padding: 20px 25px;
  color: #fff;
  margin: 50px auto;
  position: relative;
}
.slick-track .slick-slide:nth-child(2n-1) .roadmap_carousel_div {
  background: url("../src/assets/images/roadmap_02.png");
}
.roadmap_carousel_div p {  
  font-size: 24px;
  padding: 100px 50px 0 10px;
}
.slick-track .slick-slide:nth-child(2n-1) .roadmap_carousel_div p {
  padding: 15px 50px 0 10px;
}
.roadmap_carousel_div h2 {
  margin: 0;
  position: absolute;
  top: 25px;
  left: 60px;
  font-size: 36px;
  font-weight: 600;
}
.slick-track .slick-slide:nth-child(2n-1) .roadmap_carousel_div h2 {
  top: unset;
  bottom: 30px;
}
.slick_carousel_custom .slick-prev {
  background: url("../src/assets/images/slick_prev.png") !important;
  left: 0;
}
.slick_carousel_custom .slick-next {
  background: url("../src/assets/images/slick_next.png") !important;
  right: 0;  
}
.slick_carousel_custom .slick-prev, 
.slick_carousel_custom .slick-next {
  width: 99px;
  height: 35px;
  z-index: 4;
  filter: invert(1) hue-rotate(45deg) contrast(0.85) brightness(0.85);
  transition: 0.2ms all;
  top: unset;
  bottom: -50px;
}
.slick_carousel_custom .slick-prev:before, 
.slick_carousel_custom .slick-next:before {
  display: none;
}
.slick_carousel_custom .slick-prev:hover,
.slick_carousel_custom .slick-next:hover {
  filter: unset;
}
.team_panel {
  position: relative;
  text-align: center;
  margin-bottom: 50px;
  background-color: #375ace;
  border-radius: 10px;
  padding: 32px 20px 36px;  
  transition: .3s cubic-bezier(.24,.74,.58,1);
  width: 270px;
  height: 330px;
}
.team_item_image {
  width: 130px;
  height: 130px;
  padding: 3px;
  position: relative;
  margin: 0 auto 24px;
  border-radius: 130px;
  z-index: 1;
}
.team_item_image:before {
  content: '';
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 130px;  
  background-image: linear-gradient(to bottom,#f54b31,#f54b31);
}
.team_item_image img {
  border: 3px solid #375ace;
  border-radius: 130px;
  width: 125px;
  height: 125px;
  object-fit: cover;
  object-position: top;
}
.team_item_title {
  margin-bottom: 8px;
  font-size: 28px;
  font-weight: 600;
  color: #fff;
}
.team_item_position {
  font-size: 20px;
  font-weight: 400;
  color: #c0dcf2;
  margin-bottom: 18px;
}
.team_panel .team_item_details {
  width: 45px;
  height: 45px;
  display: inline-block;
  background-color: #2445a6;
  font-size: 42px;
  font-weight: 500;
  line-height: 40px;
  border-radius: 100%;
  color: #fff;
  text-decoration: none !important;
}
.team_panel_hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: bottom center;
  transition: .3s cubic-bezier(.24,.74,.58,1);
  background-repeat: no-repeat;
  background-size: cover;
}
.team_panel_hover:before {
  content: '';
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(-45deg,#375ace,#375ace);
  opacity: .85;
  z-index: -1;
}
.team_item_desc {
  color: #fff;
  line-height: 1.5;
  font-size: 20px;
  margin-bottom: 20px;
}
.team_item_social {
  padding-top: 5px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}
.team_item_social a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  background-color: #ffffff;
  border-radius: 100%;
  line-height: 35px;
  font-size: 20px;
  color: #1a3a96;
  margin: 0 3px 10px;
  text-decoration: none;
  transition: 0.2s all;
}
.team_item_social a:hover {
  background-color: #1a3a96;
  color: #fff;
}
.team_panel:hover .team_panel_hover {
  opacity: 1;
  transform: scaleY(1);
}
.team_section {
  padding-bottom: 80px;
}
h2.accordian_head {
  color: #d1eefe;
  margin: 3px 0 5px;
  font-size: 24px;
  text-align: left;
}
p.accordian_para {
  text-align: left;
  color: #fff;
  font-size: 20px;
  line-height: 30px;
}
.faq_setion_panel .MuiAccordion-root {
  margin: 20px 0;
  border-left: 4px solid #f54b31;
  border-radius: 5px !important;
  background-color: #2b4bb2;
  box-shadow: 3px 4px 6px rgb(17 17 17 / 0.1);
  position: relative;
  padding: 10px 0 10px 10px;
}
.faq_setion_panel .MuiAccordionSummary-root.Mui-expanded {
  min-height: 50px;
  
}
.faq_setion_panel .MuiAccordion-root .Mui-expanded h2.accordian_head {
  color: #fff;
}
.faq_setion_panel .MuiAccordion-root.Mui-expanded {
  background-color: #375ace;
}
.faq_setion_panel .MuiAccordionSummary-content {
  margin: 0 !important;
}
.contact_address {
  display: flex;
  flex-wrap: wrap;
}
.contact_address div {
  display: flex;
  align-items: center;
  width: 40%;
  margin-bottom: 30px;
}
.contact_address div img {
  margin-right: 15px;
}
.contact_address div h4 {
  color: #d1eefe;
  font-size: 24px;
  font-weight: 500;
  margin: 0;
}
.contact_address div h4 a {
  color: #d1eefe;
  text-decoration: none;
}
.contact_address div:nth-child(1) {
  width: 100%;
}
.footer_contact_form form {
  background-image: linear-gradient(2deg,#2e1a18,#991d25)!important;
  border-radius: 30px;
  box-shadow: -1px -1px 3px -1px rgb(195 195 195/40%), 1px 2px 3px -1px rgb(0 0 0/.8);
  padding: 10px 120px 25px;
  margin-top: 50px;
}
.footer_contact_form form h2 {
  font-size: 24px;
  color: #ffab00;
}
.footer_contact_form form .auth_inp {
  width: 100%;
  border-radius: 5px;
  height: 42px;
  font-size: 16px;
  padding: 8px;
  background-color: #f5e8e9;
  border: 1px solid #991d25;
  color: #000;
}
.footer_contact_form form .auth_inp:focus {
  border: 1px solid #0f3eff;
}
footer.footer_home {
  background-color: #1a3a96;
  padding: 30px 0;
}
/* footer.footer_home .login_social_links {
  margin-top: 250px;
  margin-bottom: 40px;
} */
.footer_contact_form {
  position: absolute;
  z-index: 5;
}
.form_text_prepend {
  position: relative;
}
form.auth_form label {
  font-size: 20px;
  font-weight: 500;
  color: #d1eefe;
  margin-bottom: 10px;
}
form.auth_form .auth_inp {
  width: 100%;
  border: 1px solid #e6e6e6;
  background-color: #fff !important;
  font-size: 18px;
  padding: 5px 12px;
  border-radius: 5px;
  height: 42px;
}
.avil_balance {
  color: #d1eefe;
}
form.auth_form textarea.auth_inp {
  height: unset;
  resize: none;
}
form.auth_form .form-group {
  margin-bottom: 30px;  
}
.select_span {
  position: absolute;
  top: 11px;
  right: 2px;
  width: 120px;
  border: 0;
  box-shadow: none !important;
  height: 40px;
  background: #fff url("../src/assets/images/dropdown_arrow.png") no-repeat right 0.75rem center;
}
.checkbox .cr, .radio .cr {
  position: relative;
  display: inline-block;
  border: 3px solid #e6e6e6;
  background: #fff;
  border-radius: .5rem;
  width: 28px;
  height: 28px;
  float: left;
  margin-right: .5em;
  margin-top: 0;
}
.checkbox label input[type=checkbox]+.cr>.cr-icon, .radio label input[type=radio]+.cr>.cr-icon {
  -webkit-transform: scale(3) rotate(-20deg);
  transform: scale(3) rotate(-20deg);
  opacity: 0;
  transition: all .3s ease-in;
}
.checkbox .cr .cr-icon, .radio .cr .cr-icon {
  position: absolute;
  font-size: 14px;
  line-height: 0;
  top: 50%;
  left: 20%;
  color: #e69f35;
}.checkbox label input[type=checkbox], .radio label input[type=radio] {
  display: none;
}
.checkbox label input[type=checkbox]:checked+.cr>.cr-icon, .radio label input[type=radio]:checked+.cr>.cr-icon {
  -webkit-transform: scale(1) rotate(0deg);
  transform: scale(1) rotate(0deg);
  opacity: 1;
}
span.tcLabelText {
  font-size: 16px;
  color: #fff;
}
p.address_btn {
  margin-bottom: 0;
  width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 24px;
}
.footer_bottom_home {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer_bottom_home_left ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}
.footer_bottom_home_left ul li {
  margin-right: 30px;
  position: relative;
}
.footer_bottom_home_left ul li::after {
  content: "";
  background-color: #fff;
  width: 1px;
  height: 15px;
  position: absolute;
  top: 7px;
  right: -15px;
}
.footer_bottom_home_left ul li a {
  font-size: 18px;
  color: #fff;
}
.footer_bottom_home_left ul li:last-child:after {
  display: none;
}
.footer_bottom_home_left ul li a:hover {
  color: #f54b31;
}

/* Connect Wallet */
.primary_modal .modal-content {
  background-color: #1a3a96;
}
.primary_modal .modal-header {
  border-bottom: 1px solid #122b74;
  padding: 20px 24px 15px;
}
.connect_wallet_button {
  border: 2px solid #d1d5da;
  background-color: transparent;
  color: #111111;
  border-radius: 23px;
  padding: 0 24px;
  outline: 0;
  line-height: 46px;
  height: 52px;
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
}
.connect_wallet_button:hover {
  border-color: #375ace;
}
.primary_modal .modal-header h5 {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  color: #d1eefe;;
}
.primary_modal .modal-header .btn-close {
  filter: invert(1);
}
.top_navbar ul li::after {
  display: none;
}
.connect_wallet_button img {
  margin-right: 15px;
}
.connect_wallet_button span {
  color: #d1eefe;
  font-size: 22px;
  font-weight: 400;
}
.djack_equavalent.djack_equavalent_buy_sec {
  background-color: #395bbf;
  padding: 15px;
  margin: 15px 0 30px;
  border-radius: 5px;
}
.form-control.custom-select{
  background: #fff !important;
  padding-left: 0 !important;
}
.css-yk16xz-control,
.css-1pahdxg-control {
	background-color: #fff !important;
    border-color: #fff !important;
    color: #000 !important;
}
.customSelect > div > div > div{
  color: #000 !important;
}
.css-1uccc91-singleValue {
    color: #000 !important;
}
.css-26l3qy-menu {
	background-color: #fff !important;
    border: 1px solid #414073 !important;
    margin: 0 !important;
    padding: 0 !important;
    top: 45px !important;    
}
/* .css-26l3qy-menu > div > div:hover,
.css-26l3qy-menu > div > div:focus {
	background-color: #f4795c !important;
} */
.css-1rhbuit-multiValue {
    background-color: #333232 !important;
}
.css-12jo7m5 {
	color: #fff !important;
}
.customSelect > div{
  background: #fff !important;
  color: #000 !important;
  opacity: 1 !important;
  border: 0 !important;
  outline: none !important;
  box-shadow: none !important;
  z-index: 999;
}
.css-1okebmr-indicatorSeparator {
  background-color: #fff !important;
}
.rdt_Pagination {
  background: transparent !important;
  color: #fff !important; 
}
.rdt_Pagination button:disabled {
  color: rgb(255 255 255 / 30%);
  fill: rgb(255 255 255 / 30%);
}
.rdt_Pagination button {
  color: rgb(255 255 255 / 70%);
  fill: rgb(255 255 255 / 70%);
}
.walletDropMain {
  background-color: #2445a6;
  padding: 20px;
  color: #fff;
  min-width: 300px;
  border-radius: 0.25rem;
}
.walletDropMain p {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logoutLink {
  display: block;
  background: #f54b31;
  border: 2px solid #f54b31;
  color: #fff;
  width: 100%;
  padding: 12px 15px;
  text-align: center;
  border-radius: 30px;
  margin-top: 10px;
  text-decoration: none;
  font-size: 20px;
  font-weight: 500;
}
.logoutLink:hover {
  text-decoration: none;
  color: #fff;
  background: transparent;
  border: 2px solid #f54b31;
}
.dropdown-toggle:after {
  will-change: transform;
  transition: -webkit-transform .15s linear;
  transition: transform .15s linear;
  transition: transform .15s linear,-webkit-transform .15s linear;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
.dropdown .show .dropdown-toggle:after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.rdt_TableCell a {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 260px;
    white-space: nowrap;
}
.banner_img {
  position: relative;
}
.banner_img::before {
  content: "";
  background-color: #2445a6;
  filter: blur(35px);
  position: absolute;
  top: 150px;
  width: 180px;
  height: 180px;
  z-index: 1;
  right: -10px;
}

.footerHover{
  cursor: pointer;
}

/* Media Queries */
@media screen and (max-width: 1279px) {
  .token_details_panel_left::before {
    background: #f3d690;
    width: 100%;
    left: 0;
  }
  .top_navbar ul {
    display: block;
  }
  .top_navbar ul li {
    float: none;
    margin: 10px 0;
  }
  button.menu_btn {
    margin-top: 10px;
  }
}
@media screen and (max-width: 1199px) {
  h1.main_title {
    font-size: 40px;
  }
  .team_panel {
    margin: 0 auto 50px;
  }
}
@media screen and (max-width: 991px) {
  .page-header h1 {
    font-size: 40px;
    line-height: 50px;
  }
  .timer_time {
    font-size: 46px;
  }
  span.timer_label {
    font-size: 18px;
  }
  .banner_token_btn button.primary_btn {
    font-size: 18px;
    margin-top: 8px;
  }
  .ico_details_panel h2 {
    font-size: 16px;
  }
  .page-header button.primary_btn {
    font-size: 28px;
    padding: 3px 50px;
  }
}
@media screen and (max-width: 959px) {  
  ul.slick_slider_list li {
    margin: 5px 0 0 30px;
    font-size: 14px;
    line-height: 14px;
  }
  .page-header button.primary_btn {
    font-size: 24px;
    padding: 3px 40px;
  }
  .about_section h3 {
    font-size: 28px;
  }
  .contact_address div h4 {
    font-size: 20px;
  }
  .footer_bottom_home {
    display: block;
    text-align: center;
  }
  .footer_bottom_home_left ul {
    justify-content: center;
  }
  .copyright_txt {
    text-align: center;
    margin: 15px 0;
  }
  .page-header h4 {
    line-height: 32px;
  }
}
@media screen and (max-width: 767px) {
  .homepage_container {
    padding-top: 150px !important;
    padding-bottom: 10px !important;
  }
  .account_details_panel {
    display: block;
    text-align: center;
  }
  .account_details_panel .text-right {
    text-align: center !important;
  }
  ul.dash_inner_menu {
    padding: 10px 10px 0;
  }
  .dash_inner_menu li {
    margin: 0 5px;
  }
  .dash_inner_menu li a.nav-link {
    font-size: 14px;
    padding: 10px 5px;
  }
  .auth_panel.forgot_panel {
    padding-bottom: 0;
  }
  .whitepaper_panel {
    right: 20px;
  }
  .footer_contact_form form {
    padding: 10px 60px 25px;
  }
  h2.accordian_head {
    font-size: 22px;
  }
  p.accordian_para {
    font-size: 16px;
  }
  .login_social_links {
    display: block;
    text-align: center;
  }
  .login_social_links ul li {
    margin: 10px 12px;
  }
  .about_section iframe {
    width: 100%;
  }
  .roadmap_carousel_div p {
    font-size: 20px;
  }
  p.home_para {
    font-size: 18px;
  }
  .ico_details_panel {
    display: block;
  }
  .ico_details_panel > div {
    width: 100%;
  }
  .footer_bottom_home_left ul {
    flex-wrap: wrap;
  }
  .contact_address div {
    width: 100%;
  }
  .wallet_address_panel h2,
  .table_box h2 {
    font-size: 24px;
  }
  .banner_img {
    display: none;
  }
  .why_choose_div {
    width: 100%;
  }
}
@media screen and (max-width: 599px) {
  .roadmap_carousel .slick-dots {
    margin: 0;
  }
  .roadmap_carousel .slick-dots li::before {
    font-size: 14px;
  }
}
@media screen and (max-width: 540px) {
  form.auth_form {
    padding: 20px 10px 20px !important ;
  }
  .auth_main_div {
    border-radius: 30px;
  }
  .auth_main_div .nav-pills {
    padding: 0;
  }
  .wallet_address_panel {
    padding: 5px 10px 10px;
  }
  button.tron_link_btn {
    font-size: 14px;
  }
  .djack_equavalent,
  .token_details_panel h2,
  .wallet_address span,
  .deposit_address_sec h4 {
    font-size: 16px;
  }
  button.primary_btn {
    font-size: 14px;
  }
  .table_box .d-flex {
    display: block !important;
    margin-bottom: 15px;
  }
  .table_box .d-flex button {
    margin-top: 10px;
  }
  .dash_inner_padding {
    padding: 5px 10px 40px;
  }
  h2.dash_subtitle {
    font-size: 16px !important;
  }
  .account_details_panel h2 {
    font-size: 14px !important;
  }
  form.auth_form .file_upload_group .auth_inp {
    font-size: 12px;
  }
  .file_upload_group .custom-file-input:lang(en)~.custom-file-label::after {
    padding: 8px 24px;
  }
  .timer_time {
    font-size: 36px;
  }
  span.timer_dots {
    font-size: 36px;
    line-height: 75px;
  }
  .whitepaper_section {
    height: auto;
  }
  .whitepaper_panel {
    right: unset;
    width: auto;
    position: relative;
  }
  .ico_details_panel h2 {
    font-size: 18px;
  }
  .ico_details_panel p {
    font-size: 18px;
  }
  h1.main_title {
    font-size: 28px;
  }
  .footer_contact_form form {
    padding: 10px 15px 25px;
    border-radius: 10px;
  }
  .footer_contact_form {
    left: 10px;
    right: 10px;
  }
  .section {
    padding: 40px 0;
  }
  p.home_para {
    font-size: 16px;
  }
  .team_details h2 {
    font-size: 18px;
  }
  .team_details h4 {
    font-size: 14px;
  }
  .token_sale_notes {
    display: block;
  }
  .banner_token_btn {
    position: relative;
  }
  .banner_token_btn button.primary_btn {
    padding: 8px 30px;
  }
  .roadmap_section {
    padding: 40px 0 100px;
  }  
  .team_panel {
    margin: 20px auto 50px;
  }
  .about_section h3 {
    font-size: 22px;
  }
  .why_choose_div h2 {
    font-size: 60px;
  }
  .why_choose_div {
    width: 100%;
  }
  .why_choose_div h3 {
    font-size: 32px;
  }
  .why_choose_div:hover::before {
    height: 350px;
  }
  .chart_asset_details ul li label {
    font-size: 18px;
  }
  .chart_asset_details ul li::before {
    top: 16px;
  } 
}